/* You can add global styles to this file, and also import other style files */
.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-0 {
    padding-left: 0 !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

/* Sweet alert */

.swal2-title {
    display: inline-block !important;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content: "";
}

@keyframes glowing {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    position: absolute;
    color: rgb(91, 167, 234);
    z-index: 100000 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}
ngx-spinner .loading-text {
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}
.p-checkbox-label {
    display: block !important;
    margin-bottom: 0 !important;
}
.btn.btn-outline-grey {
    color: rgb(var(--gray-dark));
    background-color: #0000;
    border-color: rgb(var(--secondary));
}
.btn.btn-outline-grey:hover {
    color: rgb(var(--dark));
    background-color: #0000;
    border-color: rgb(var(--dark));
}

.center-fa-i {
    display: inline-table !important;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.card-shadow:not(.card-front):not(.card-back) {
    -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2), 0 1px 10px rgba(0,0,0,0.15) !important;
    box-shadow: 0 0 1px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2), 0 1px 10px rgba(0,0,0,0.15) !important;

}

.card-shadow:not(.card-front):not(.card-back).hvr-overline-from-center {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
}

.card-shadow:not(.card-front):not(.card-back).hvr-overline-from-center:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    top: 0;
    background: rgb(var(--primary));
    height: var(--card-overline-height);
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.card-shadow:not(.card-front):not(.card-back).hvr-overline-from-center:hover:before,
.card-shadow:not(.card-front):not(.card-back).hvr-overline-from-center:focus:before,
.card-shadow:not(.card-front):not(.card-back).hvr-overline-from-center:active:before {
    left: 0;
    right: 0;
}

@media screen and (max-width: 40em) {
    .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        width: 100% !important;
        text-align: right !important;
    }
    .p-column-title {
        float: left;
        text-align: left;
    }
}
#confirmation {
    align-items: flex-start;
}
#confirmation .p-checkbox-label {
    white-space: pre-wrap;
    line-height: 1.5;
}

.env-bar {
    line-height: 40px;
    color: white;
    z-index: -1;
}

.maintenance-info {
    line-height: 40px;
    background-color: rgb(218, 5, 5);
    color: white;
    z-index: -1;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media screen and (max-width: 995px) {
    .maintenance-info {
      margin-top: 15%;
    }
  }

.env-test {
    background-color: rgb(121, 4, 4);
}

.env-entw {
    background-color: rgb(176, 73, 3);
}

.env-demo {
    background-color: rgb(67, 12, 157);
}

.dropdown-index {
    z-index: 3;
}

.centered-content {
    text-align:center;
    word-break:break-all;
    font-size: 18pt;
}

.mobile-show {
    display: none;
}

.valid-color {
    color: #40B261
}

.invalid-color {
    color: #EA161F;
}

.warning-color {
    color: #EA7716;
}

.selectable {
    cursor: pointer;
}

.p-checkbox .p-checkbox-box.p-disabled .pi-check {
    /* disabled checkboxes */
    color: rgb(var(--primary));
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .mobile-hide {
        display: none;
    }

    .mobile-show {
        display: block !important;
    }

    .mobile-flex-row-wrap {
        flex-flow: row wrap;
    }

    .mobile-w-100 {
        width: 100%;
    }

    .mobile-p-top-10 {
        padding-top: 10px;
    }

  }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5) auto;
  }
  .btn-close {
    box-sizing: content-box;
    width: 1rem;
    height: 1rem;
    padding: 0.25em 0.25em;
    color: #000000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
    border: 0;
    border-radius: 0.475rem;
    opacity: 0.5;
  }
  .btn-close:hover {
    color: #000000;
    text-decoration: none;
    opacity: 0.75;
  }
  .btn-close:focus {
    outline: 0;
    box-shadow: none;
    opacity: 1;
  }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25;
  }

  .btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%);
  }

/* Don't display the password reveal icon in the edge browser */
::-ms-reveal {
    display: none;
}
